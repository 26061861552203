import React from "react"
import { node } from "prop-types"
import parser from "react-html-parser"

const Coursetopicsgdpaiso = ({node}) => {
    let slug = node.acf.single_courses_slug
    let pt, btn1
    if(slug == "gdpr-and-dpa-awareness") {
         pt= 'gdpr_tropic';
         btn1 = ''
    } else if(slug == "iso27001-awareness") {
        pt = 'iso27001_tropic';
        btn1 = '';
    }
    
    return (
        <>
        <section className="coursetopics coursetropicforiso" id={pt}>
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6 course-topic-headline text-center">
              <h3>Course Topics</h3>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row pt-lg-1 pb-lg-4 py-md-3 tropic-section">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 col-md-12">
              <div className="row pb-2 mt-2 isosec1">
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center ">
                  <img
                    src={
                      node.acf.info_single_course_tropic_icon_1.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="icon1"
                    className="isosec1_icon"
                  />
                </div>
                <div className="col-lg-10 col-md-10 isosec1_title">
                  {parser(node.acf. info_singlr_course_tropic_text_1)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0 " />
              <div className="row  pt-3 pb-2 alignicon">
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center"> 
                  <img
                    src={
                      node.acf. info_single_course_tropic_icon_2.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="icon2"
                  />
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.info_singlr_course_tropic_text_2)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0" />
              <div className="row  pt-3">
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                  <img
                    src={
                      node.acf. info_single_course_tropic_icon_3.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="icon3"
                  />
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.info_singlr_course_tropic_text_3)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0" />
              <div className="row  pt-3 coursetropic4">
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                  <img
                    src={
                      node.acf. info_single_course_tropic_icon_4.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="icon4"
                  />
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.info_singlr_course_tropic_text_4)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0" id={btn1} />
              <div className="row  pt-3 coursetropic4" id={btn1}>
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                  <img
                    src={
                      node.acf. info_single_course_tropic_icon_5.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="icon5"
                  />
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.info_singlr_course_tropic_text_5)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0 d-lg-none d-block" />
            </div>
            <div className="col-lg-6 col-md-12 secondtab">
              <div className="row pt-2 pb-2 iconalignment">
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                <img
                    src={
                      node.acf.info_single_course_tropic_icon_6.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="icon7"
                  />
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.info_singlr_course_tropic_text_6)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0"/>
              <div className="row  pt-3 iconalignment isosec7" >
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                <img
                    src={
                      node.acf.info_single_course_tropic_icon_7.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="icon7"
                  />
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.info_singlr_course_tropic_text_7)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0"/>
              <div className="row  pt-3 iconalignment" >
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                <img
                    src={
                      node.acf.info_single_course_tropic_icon_8.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="icon7"
                  />
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.info_singlr_course_tropic_text_8)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0"/>
              <div className="row  pt-3 iconalignment" >
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                <img
                    src={
                      node.acf.info_single_course_tropic_icon_9.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="icon7"
                  />
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.info_singlr_course_tropic_text_9)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0"/>
              <div className="row  pt-3 iconalignment" >
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                <img
                    src={
                      node.acf.info_single_course_tropic_icon_10.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="icon7"
                  />
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.info_singlr_course_tropic_text_10)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0 isosec11"/>
              <div className="row  pt-3 iconalignment isosec11" >
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                <img
                    src={
                      node.acf.info_single_course_tropic_icon_11.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="icon7"
                  />
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.info_singlr_course_tropic_text_11)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-5 mb-3"/>
      </section>
        </>
    )
}

export default Coursetopicsgdpaiso