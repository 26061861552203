import React from "react"
import { node } from "prop-types"
import parser from "react-html-parser"

const Phishingsection2 = ({node}) => {
    let image1 = node.acf
    
    return(
        <>
        <section className="phishing-section2 py-4" id="phishing-section2">
            <div className="container-fluid">
         
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 col-md-12 crs-highlights">
              <h3>{parser(node.acf.phishing_head_title)}</h3>
            </div>
            <div className="col-lg-3"></div>
          </div>
          <div className="row pt-4 text-center">
               
                
                <div className="col-lg-4 col-md-12 py-md-3 crs-title">
                  <img
                    src={
                      node.acf.phishing_tool_img1
                        .localFile.childImageSharp.fluid.originalImg
                    }
                    alt={node.acf.phishing_tool_title1}
                  />
                  <div className="phish-description">
                   <h4>{parser(node.acf.phishing_tool_title1)}</h4>
                  <p>{parser(node.acf.phishing_tool_desc1)}</p>
                  </div>

                 
                </div>

                <div className="col-lg-4 col-md-12 py-md-3 crs-title">
                  <img
                    src={
                      node.acf.phishing_tool_img2
                        .localFile.childImageSharp.fluid.originalImg
                    }
                    alt={node.acf.phishing_tool_title2}
                  />
                  <div className="phish-description">
                  <h4>{parser(node.acf.phishing_tool_title2)}</h4>
                  <p>{parser(node.acf.phishing_tool_desc2)}</p>
                  </div>
                </div>

                <div className="col-lg-4 col-md-12 py-md-3 crs-title">
                  <img
                    src={
                      node.acf.phishing_tool_img3
                        .localFile.childImageSharp.fluid.originalImg
                    }
                    alt={node.acf.phishing_tool_title3}
                  />
                  <div className="phish-description">
                  <h4>{parser(node.acf.phishing_tool_title3)}</h4>
                  <p>{parser(node.acf.phishing_tool_desc3)}</p>
                  </div>
                </div>
            
          </div>

          <div className="row pb-4 text-center second-phish-row">
           
                  <div className="col-md-2"></div>
              <div className="col-lg-4 col-md-12 py-md-3 crs-title">
                  <img
                    src={
                      node.acf.phishing_tool_img4
                        .localFile.childImageSharp.fluid.originalImg
                    }
                    alt={node.acf.phishing_tool_title4}
                  />
                  <div className="phish-description">
                  <h4>{parser(node.acf.phishing_tool_title4)}</h4>
                  <p>{parser(node.acf.phishing_tool_desc4)}</p>
                  </div>
                </div>

                <div className="col-lg-4 col-md-12 py-md-3 crs-title">
                  <img
                    src={
                      node.acf.phishing_tool_img5
                        .localFile.childImageSharp.fluid.originalImg
                    }
                    alt={node.acf.phishing_tool_title5}
                  />
                   <div className="phish-description">
                  <h4>{parser(node.acf.phishing_tool_title5)}</h4>
                  <p>{parser(node.acf.phishing_tool_desc5)}</p>
                  </div>
                </div>
                <div className="col-md-2"></div>


               
              
          </div>

            </div>
        </section>
        <hr className="infosec-hr" />
        </>
    )
}

export default Phishingsection2