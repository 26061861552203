import React from "react"
import { node } from "prop-types"
import parser from "react-html-parser"
import { Link } from "gatsby"

const Coursetopics = ({ node }) => {
  let slug = node.acf.single_courses_slug
  let btn
  let btn1, pt, btn2, showsec, coursesec2

  if (slug == "security-awareness") {
    btn = "infosec-awareness"
    pt = ''
    showsec = ""
    coursesec2 = ""
  } else if (slug == "hipaa-compliance-awareness") {
    btn1 = "hideclass"
    btn = "hideclass"
    pt = "aligntropic"
    showsec = "hidesec"
    coursesec2 = ""
  } else if (slug == "ferpa-compliance-awareness") {
    btn = ""
    btn2 = "hideclass"
    showsec = "hidesec"
    coursesec2 = ""
  }else if (slug == "pci-dss-awareness") {
    btn = ""
    btn2 = "hideclass"
    showsec = "hidesec"
    coursesec2 = "pci"
  } else if (slug == "security-tips-for-remote-workforce") {
    btn = ""
    btn2 = "hideclass"
    showsec = "hidesec"
    coursesec2 = "pci"
    pt = 'securitypadding'
  }  
   else {
    btn = "other-crse"
    pt = ''
    btn2 = ""
    showsec = "hidesec"
    coursesec2 = ""
  }
  console.log(slug)
  // if (slug == "") {
  //   h3titles = props.harassh3title
  // } else if (Page == "Financial") {
  //   h3titles = props.financialh3title
  // } else {
  //   h3titles = props.h3title
  // }
  return (

    <>
      <section className={"coursetopics " +coursesec2}  id="coursetopics">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6 course-topic-headline text-center">
              <h3>Course Topics</h3>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row pt-lg-1 pb-lg-4 py-md-3 tropic-section">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 col-md-12" id={pt}>
              <div className="row ">
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center ">
                  <picture>
                    <source
                      media="(max-width:480px)"
                      srcset={
                        node.acf.infosec_single_crse_topic_1_icon_mobile
                          .localFile.childImageSharp.fluid.originalImg
                      }
                    />

                    <img
                      src={
                        node.acf.infosec_single_crse_topic_1_icon.localFile
                          .childImageSharp.fluid.originalImg
                      }
                      alt="icon1"
                    />
                  </picture>
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.infosec_single_crse_text1)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0 " />
              <div className="row  pt-3">
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                  <picture>
                    <source
                      media="(max-width:480px)"
                      srcset={
                        node.acf.infosec_single_crse_topic_2_icon_mobile
                          .localFile.childImageSharp.fluid.originalImg
                      }
                    />

                    <img
                      src={
                        node.acf.infosec_single_crse_topic_2_icon.localFile
                          .childImageSharp.fluid.originalImg
                      }
                      alt="icon2"
                    />
                  </picture>
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.infosec_single_crse_text2)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0" />
              <div className="row  pt-3">
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                  <picture>
                    <source
                      media="(max-width:480px)"
                      srcset={
                        node.acf.infosec_single_crse_topic_3_icon_mobile
                          .localFile.childImageSharp.fluid.originalImg
                      }
                    />

                    <img
                      src={
                        node.acf.infosec_single_crse_topic_3_icon.localFile
                          .childImageSharp.fluid.originalImg
                      }
                      alt="icon3"
                    />
                  </picture>
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.infosec_single_crse_text3)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0" />
              <div className="row  pt-3">
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                  <picture>
                    <source
                      media="(max-width:480px)"
                      srcset={
                        node.acf.infosec_single_crse_topic_4_icon_mobile
                          .localFile.childImageSharp.fluid.originalImg
                      }
                    />

                    <img
                      src={
                        node.acf.infosec_single_crse_topic_4_icon.localFile
                          .childImageSharp.fluid.originalImg
                      }
                      alt="icon4"
                    />
                  </picture>
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.infosec_single_crse_text4)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0" id={btn1} />
              <div className="row  pt-3" id={btn1}>
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                  <picture>
                    <source
                      media="(max-width:480px)"
                      srcset={
                        node.acf.infosec_single_crse_topic_5_icon_mobile
                          .localFile.childImageSharp.fluid.originalImg
                      }
                    />

                    <img
                      src={
                        node.acf.infosec_single_crse_topic_5_icon.localFile
                          .childImageSharp.fluid.originalImg
                      }
                      alt="icon5"
                    />
                  </picture>
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.infosec_single_crse_text5)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0" id={btn1} />
              <div className="row  pt-3" id={btn1}>
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                  <picture>
                    <source
                      media="(max-width:480px)"
                      srcset={
                        node.acf.infosec_single_crse_topic_6_icon_mobile
                          .localFile.childImageSharp.fluid.originalImg
                      }
                    />

                    <img
                      src={
                        node.acf.infosec_single_crse_topic_6_icon.localFile
                          .childImageSharp.fluid.originalImg
                      }
                      alt="icon6"
                    />
                  </picture>
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.infosec_single_crse_text6)}
                </div>
              </div>
              <hr className="infosec-crse-line m-lg-0 m-md-0" id={btn} />
              <div className="row  pt-3" id={btn}>
                <div className="col-lg-2 col-md-2 img-mobile text-lg-right text-md-right  text-center">
                  <picture>
                    <source
                      media="(max-width:480px)"
                      srcset={
                        node.acf.infosec_single_crse_topic_7_icon_mobile
                          .localFile.childImageSharp.fluid.originalImg
                      }
                    />

                    <img
                      src={
                        node.acf.infosec_single_crse_topic_7_icon.localFile
                          .childImageSharp.fluid.originalImg
                      }
                      alt="icon7"
                    />
                  </picture>
                </div>
                <div className="col-lg-10 col-md-10">
                  {parser(node.acf.infosec_single_crse_text7)}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 d-none d-lg-block coursesec2">
              <img
                src={
                  node.acf.infosec_single_course_topic_image.localFile
                    .childImageSharp.fluid.originalImg
                }
                alt="infosec-crse-image"
              />
            </div>
          </div>

          <div className="row" id={showsec}   >
            <div className="col-lg-3"></div>
            <div className="col-lg-6 col-md-12 infosec-crse-topic-btn">
            <Link to="/Infosec-data-protection/security-tips-for-remote-workforce/">
              <button className="infosec-crse-topics">
                Includes Security Tips for Remote Workforce Microlearning
              </button>
              </Link>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </section>


     

      <hr className="infosec-hr" />
    </>
  )
}

export default Coursetopics
