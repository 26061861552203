import React from "react"
import { node } from "prop-types"
import parser from "react-html-parser"

const Phishingsection1 = ({node}) => {
    let image1 = node.acf
    
    return(
        <>
        <section className="phishing-section1" id="phishing-section1">
            <div className="container-fluid">
            <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 py-4 responsive-spacing">
              <div className="row">
                <div class="col-lg-4 col-md-12 py-md-3">
                  <img
                    src={
                      node.acf.first_image.localFile.childImageSharp.fluid.originalImg
                    }
                    alt={node.acf.first_image_title}
                  />
                  <h4 className="train-analyze">{parser(node.acf.first_image_title)}</h4>
                </div>

                <div class="col-lg-4 col-md-12 py-md-3">
                  <img
                    src={
                      node.acf.second_image.localFile.childImageSharp.fluid.originalImg
                    }
                    alt={node.acf.second_image_title}
                  />
                  <h4 className="train-analyze"> {parser(node.acf.second_image_title)}</h4>
                </div>

                <div class="col-lg-4 col-md-12 py-md-3">
                  <img
                    src={
                        node.acf.third_image.localFile.childImageSharp.fluid.originalImg
                      }
                    alt={node.acf.third_image_title}
                  />
                  <h4 className="train-analyze">{parser(node.acf.third_image_title)}</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
            </div>
        </section>
        <hr className="infosec-hr" />
        </>
    )
}

export default Phishingsection1