import React from "react"
import Layout from "../components/layout"

import Infosechighlights from "../components/infosec-single-courses/infosec-course-highlights"
import Platformhighlights from "../components/infosec-single-courses/infosec-platform-highlights"
import YouTubeapi from "../components/infosec-single-courses/info-single-embed"
import Infosecsinglehero from "../components/infosec-single-courses/infosec-single-hero"
import Infosecherodesc from "../components/infosec-single-courses/infosec-hero-bottomdesc"
import Infopricing from "../components/infosec-single-courses/infosec-single-pricing"
import Coursetopics from "../components/infosec-single-courses/infosec-course-topics"
import Singlecourseform from "../components/infosec-single-courses/info-single-course-form"
import Coursetopicsgdpaiso from "../components/infosec-single-courses/gdpr_iso_course_tropic"
import Phishingsection1 from "../components/infosec-single-courses/phishing-section1"
import Phishingsection2 from "../components/infosec-single-courses/phishing-section2"

import Relatedcourses from "../components/related_courses"
// import SEO from "../components/Seo"
import { SEO, useSEO } from "gatsby-plugin-seo"
import "../css/single-course-template/infosec.css"

const infosecsecurity = ({ data }) => {
  let infosecsinglecourses = data.wordpressAcfInfoSingleCourses
  let slugname = data.wordpressAcfInfoSingleCourses.acf.single_courses_slug
  const yoast = data.wordpressAcfInfoSingleCourses.yoast_head
  let seotitle = data.wordpressAcfInfoSingleCourses.acf.seo_title
  let seodesc = data.wordpressAcfInfoSingleCourses.acf.seo_description

  return (
    <>
      <Layout>
        <SEO
          title={seotitle}
          description={seodesc}
          pagePath={"/Infosec-data-protection/" + slugname}
          schema={`{
              "@context": "http://schema.org",
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Organization",
                "name": "SucceedLEARN - SucceedLEARN - Best in Class & Comprehensive eLearning ",
                "image": "https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-238-1.png"
              }
            }`}
        />
        <Infosecsinglehero node={infosecsinglecourses} />

        <Infosecherodesc node={infosecsinglecourses} />

        <Infopricing node={infosecsinglecourses} />

        {slugname == "phishing-attack-simulator-training" 
        ? (
          <div>          
          <Phishingsection1 node={infosecsinglecourses}/>
          <Phishingsection2 node={infosecsinglecourses}/>
          </div>

        ) : (
          <div>
          <YouTubeapi node={infosecsinglecourses} />

          <Infosechighlights node={infosecsinglecourses} />

          <Platformhighlights node={infosecsinglecourses} />
          
        {slugname == "gdpr-and-dpa-awareness" ||
        slugname == "iso27001-awareness" ? (
          <Coursetopicsgdpaiso node={infosecsinglecourses} />
        ) : (
          <Coursetopics node={infosecsinglecourses} />
        )}

          </div>

        )}

        <Singlecourseform node={infosecsinglecourses} />

        <Relatedcourses node={infosecsinglecourses} />
      </Layout>
    </>
  )
}

export default infosecsecurity

export const infosecqueries = graphql`
  query($id: String!) {
    wordpressAcfInfoSingleCourses(id: { eq: $id }) {
      id
      acf {
        single_courses_slug
        infosec_single_course_hero_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          yoast_head
        }
        infosec_single_course_hero_subtitle
        infosec_single_course_hero_title
        single_courses_slug
        infosec_single_course_hero_bottom_description

        infosec_single_course_hero_tablet_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        infosec_single_course_hero_mobile_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        infosec_single_course_pricing_button_text
        infosec_single_course_pricing_left_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_pricing_right_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        youtube_link
        infosec_single_course_highlights1_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_text1

        infosec_single_course_highlights2_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_text2

        infosec_single_course_highlights3_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_text3

        infosec_single_course_highlights4_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_text4

        infosec_single_course_highlights5_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_text5

        infosec_single_course_highlights6_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_text6

        Platform_single_course_highlights1_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Platform_single_course_text1

        Platform_single_course_highlights2_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Platform_single_course_text2

        Platform_single_course_highlights3_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Platform_single_course_text3

        Platform_single_course_highlights4_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Platform_single_course_text4

        Platform_single_course_highlights5_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Platform_single_course_text5

        Platform_single_course_highlights6_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Platform_single_course_text6

        infosec_single_course_topic_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_crse_topic_1_icon {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_crse_text1

        infosec_single_crse_topic_2_icon {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_crse_text2

        infosec_single_crse_topic_3_icon {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_crse_text3

        infosec_single_crse_topic_4_icon {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_crse_text4

        infosec_single_crse_topic_5_icon {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_crse_text5

        infosec_single_crse_topic_6_icon {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_crse_text6

        infosec_single_crse_topic_7_icon {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_crse_text7

        infosec_single_crse_topic_1_icon_mobile {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        infosec_single_crse_topic_2_icon_mobile {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        infosec_single_crse_topic_3_icon_mobile {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        infosec_single_crse_topic_4_icon_mobile {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        infosec_single_crse_topic_5_icon_mobile {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        infosec_single_crse_topic_6_icon_mobile {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        infosec_single_crse_topic_7_icon_mobile {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        info_singlr_course_tropic_text_1
        info_single_course_tropic_icon_1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        info_singlr_course_tropic_text_2
        info_single_course_tropic_icon_2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        info_singlr_course_tropic_text_3
        info_single_course_tropic_icon_3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        info_singlr_course_tropic_text_4
        info_single_course_tropic_icon_4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        info_singlr_course_tropic_text_6
        info_single_course_tropic_icon_6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        info_singlr_course_tropic_text_7
        info_single_course_tropic_icon_7 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        info_singlr_course_tropic_text_8
        info_single_course_tropic_icon_8 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        info_singlr_course_tropic_text_9
        info_single_course_tropic_icon_9 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        info_singlr_course_tropic_text_10
        info_single_course_tropic_icon_10 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        info_singlr_course_tropic_text_11
        info_single_course_tropic_icon_11 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        info_singlr_course_tropic_text_5
        info_single_course_tropic_icon_5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        first_image_title
          first_image {
            localFile {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          second_image_title
          second_image {
            localFile {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          third_image_title
          third_image {
            localFile {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }

          phishing_head_title
          phishing_tool_img1 {
            localFile {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          phishing_tool_title1
          phishing_tool_desc1

          
          phishing_tool_img2 {
            localFile {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          phishing_tool_title2
          phishing_tool_desc2

          
          phishing_tool_img3 {
            localFile {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          phishing_tool_title3
          phishing_tool_desc3

          
          phishing_tool_img4 {
            localFile {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          phishing_tool_title4
          phishing_tool_desc4

          
          phishing_tool_img5 {
            localFile {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
          phishing_tool_title5
          phishing_tool_desc5
        rlink_1
        rcoursetitle_1
        rcourseimage_1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        rlink_2
        rcoursetitle_2
        rcourseimage_2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        rlink_3
        rcoursetitle_3
        rcourseimage_3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        rlink_4
        rcoursetitle_4
        rcourseimage_4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        rlink_5
        rcoursetitle_5
        rcourseimage_5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        rlink_6
        rcoursetitle_6
        rcourseimage_6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        seo_title
        seo_description
      }
    }
  }
`
